import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row" }
const _hoisted_2 = { class: "flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0" }
const _hoisted_3 = { class: "card card-flush" }
const _hoisted_4 = {
  class: "card-header pt-7",
  id: "kt_chat_contacts_header"
}
const _hoisted_5 = {
  class: "w-100 position-relative",
  autocomplete: "off"
}
const _hoisted_6 = { class: "svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y" }
const _hoisted_7 = {
  class: "card-body pt-5",
  id: "kt_chat_contacts_body"
}
const _hoisted_8 = {
  class: "scroll-y me-n5 pe-5 h-200px h-lg-auto",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header",
  "data-kt-scroll-wrappers": "#kt_content, #kt_chat_contacts_body",
  "data-kt-scroll-offset": "0px"
}
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "symbol symbol-45px symbol-circle" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 2,
  class: "symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"
}
const _hoisted_13 = { class: "ms-5" }
const _hoisted_14 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
}
const _hoisted_15 = { class: "fw-bold text-gray-400" }
const _hoisted_16 = { class: "d-flex flex-column align-items-end ms-2" }
const _hoisted_17 = { class: "text-muted fs-7 mb-1" }
const _hoisted_18 = { class: "flex-lg-row-fluid ms-lg-7 ms-xl-10" }
const _hoisted_19 = {
  class: "card",
  id: "kt_chat_messenger"
}
const _hoisted_20 = {
  class: "card-header",
  id: "kt_chat_messenger_header"
}
const _hoisted_21 = { class: "card-title" }
const _hoisted_22 = { class: "d-flex justify-content-center flex-column me-3" }
const _hoisted_23 = {
  key: 0,
  class: "symbol-group symbol-hover"
}
const _hoisted_24 = { class: "card-toolbar" }
const _hoisted_25 = { class: "me-n3" }
const _hoisted_26 = {
  class: "card-body",
  id: "kt_chat_messenger_body"
}
const _hoisted_27 = {
  class: "scroll-y me-n5 pe-5 h-800px",
  ref: "messagesRef",
  "data-kt-element": "messages",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer",
  "data-kt-scroll-wrappers": "#kt_content, #kt_chat_messenger_body",
  "data-kt-scroll-offset": "-2px"
}
const _hoisted_28 = {
  class: "card-footer pt-4",
  id: "kt_chat_messenger_footer"
}
const _hoisted_29 = { class: "d-flex flex-stack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown4 = _resolveComponent("Dropdown4")!
  const _component_MessageIn = _resolveComponent("MessageIn")!
  const _component_MessageOut = _resolveComponent("MessageOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("form", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("input", {
              type: "text",
              class: "form-control form-control-solid px-15",
              name: "search",
              value: "",
              placeholder: "Search by username or email..."
            }, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "d-flex flex-stack py-4"
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (item.image)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: item.image,
                          alt: ""
                        }, null, 8, _hoisted_11))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: _normalizeClass([`bg-light-${item.color} text-${item.color}`, "symbol-label fs-6 fw-bolder"])
                        }, _toDisplayString(item.name.charAt(0)), 3)),
                    (item.online)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("a", _hoisted_14, _toDisplayString(item.name), 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(item.email), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(item.time), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              (_ctx.isGroupChat)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[4] || (_cache[4] = [
                    _createStaticVNode("<div class=\"symbol symbol-35px symbol-circle\"><img alt=\"Pic\" src=\"media/avatars/300-5.jpg\"></div><div class=\"symbol symbol-35px symbol-circle\"><img alt=\"Pic\" src=\"media/avatars/300-25.jpg\"></div><div class=\"symbol symbol-35px symbol-circle\"><span class=\"symbol-label bg-light-warning text-warning 40px\">M</span></div><div class=\"symbol symbol-35px symbol-circle\"><img alt=\"Pic\" src=\"media/avatars/300-9.jpg\"></div><div class=\"symbol symbol-35px symbol-circle\"><span class=\"symbol-label bg-light-danger text-danger 40px\">O</span></div><div class=\"symbol symbol-35px symbol-circle\"><span class=\"symbol-label bg-light-primary text-primary 40px\">N</span></div><div class=\"symbol symbol-35px symbol-circle\"><img alt=\"Pic\" src=\"media/avatars/300-23.jpg\"></div><a href=\"#\" class=\"symbol symbol-35px symbol-circle\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_view_users\"><span class=\"symbol-label fs-8 fw-bolder\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\" title=\"\" data-bs-original-title=\"View more users\">+42</span></a>", 8)
                  ])))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[5] || (_cache[5] = _createElementVNode("a", {
                      href: "#",
                      class: "fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                    }, "Brian Cox", -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-0 lh-1" }, [
                      _createElementVNode("span", { class: "badge badge-success badge-circle w-10px h-10px me-1" }),
                      _createElementVNode("span", { class: "fs-7 fw-bold text-gray-400" }, "Active")
                    ], -1))
                  ], 64))
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _cache[7] || (_cache[7] = _createElementVNode("button", {
                class: "btn btn-sm btn-icon btn-active-light-primary",
                "data-kt-menu-trigger": "click",
                "data-kt-menu-placement": "bottom-end",
                "data-kt-menu-flip": "top-end"
              }, [
                _createElementVNode("i", { class: "bi bi-three-dots fs-2" })
              ], -1)),
              _createVNode(_component_Dropdown4)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (item.type === 'in')
                  ? (_openBlock(), _createBlock(_component_MessageIn, {
                      key: 0,
                      ref_for: true,
                      ref: "messagesInRef",
                      name: item.name,
                      image: item.image,
                      time: item.time,
                      text: item.text
                    }, null, 8, ["name", "image", "time", "text"]))
                  : _createCommentVNode("", true),
                (item.type === 'out')
                  ? (_openBlock(), _createBlock(_component_MessageOut, {
                      key: 1,
                      ref_for: true,
                      ref: "messagesOutRef",
                      image: item.image,
                      time: item.time,
                      text: item.text
                    }, null, 8, ["image", "time", "text"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 512)
        ]),
        _createElementVNode("div", _hoisted_28, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMessageText) = $event)),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addNewMessage && _ctx.addNewMessage(...args)), ["enter"])),
            class: "form-control form-control-flush mb-3",
            "data-kt-element": "input",
            placeholder: "Type a message"
          }, null, 544), [
            [_vModelText, _ctx.newMessageText]
          ]),
          _createElementVNode("div", _hoisted_29, [
            _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"d-flex align-items-center me-2\"><button class=\"btn btn-sm btn-icon btn-active-light-primary me-1\" type=\"button\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"><i class=\"bi bi-paperclip fs-3\"></i></button><button class=\"btn btn-sm btn-icon btn-active-light-primary me-1\" type=\"button\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"><i class=\"bi bi-upload fs-3\"></i></button></div>", 1)),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addNewMessage && _ctx.addNewMessage(...args))),
              class: "btn btn-primary",
              type: "button",
              "data-kt-element": "send"
            }, " Send ")
          ])
        ])
      ])
    ])
  ]))
}